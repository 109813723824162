  .div6 {
    position: relative;
    width: 100%;
    min-height: 1008px;
    min-height: calc(100vh - 98px);
    padding: 64px 0 0 0;
    background-color: $blue_back_equipo;

    @include mobile {
      min-height: auto;
      padding: 67px 0 0 0;
    }

    .bu1,
    .bu2,
    .bu3,
    .bu4 {
      width: 36.7%;
      height: 250px;
      bottom: 0px;
      position: absolute;
      background-repeat: no-repeat;
      z-index: -1;

      @include mobile {
        display: none;
      }
    }

    .bu1,
    .bu2 {
      top: 0px;
    }

    .bu5,
    .bu6 {
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 92px;
      z-index: 0;
      background-size: contain;

      @include desktop-min {
        display: none;
      }
    }

    .bu1 {
      left: 0px;
      background-image: url('../../images/buble_nEquip_1.svg');
      background-position: -97px -90px;
    }

    .bu2 {
      right: 0px;
      background-image: url('../../images/buble_nEquip_2.svg');
      background-position: -2px -52px;
    }

    .bu3 {
      left: 0px;
      background-image: url('../../images/buble_nEquip_3.svg');
      background-position: -145px 79px;
    }

    .bu4 {
      right: 0px;
      background-image: url('../../images/equipo_4.svg');
      background-position: 16px 97px;
    }

    .bu5 {
      top: 0px;
      background-image: url('../../images/bub1_nuest_equip.svg');
    }

    .bu6 {
      bottom: 0px;
      background-image: url('../../images/bub2_nuest_equip.svg');
    }

    .titulo {
      margin: 0px 33% 13px;

      @include mobile {
        margin: 0 19% 13px;
      }
    }

    .perfil {
      display: flex;
      justify-content: center;
      width: 70%;
      margin: auto;

      @include mobile {
        flex-direction: column;
        // width: 100%;
      }

      .imagen_colaborador {
        width: 45%;
        height: 350px;
        background-image: url('../../images/bubble_back_person.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 85% 85%;
        opacity: 1;
        filter: grayscale(0);
        transform: scale(1);
        transition: all .8 linear;

        &.show {
          opacity: 0.2;
          filter: grayscale(100);
          transform: scale(0.9);
        }

        @include mobile {
          width: 80%;
          height: auto;
          min-height: 180px;
          margin: 0 auto 2px;
        }


        img {
          display: block;
          width: 73%;
          margin: 0 auto;

          @include mobile {
            width: 70%;

          }
        }

      }

      .info_colaborador {
        width: 37%;
        color: $blue-font;
        padding: 67px 0 0 18px;

        @include mobile {
          padding: 0;
          width: 87%;
          margin: 0 auto;
        }

        .nombre,
        .saludo {
          text-transform: uppercase;
          font-family: 'raleway-extra-bold';
          font-size: 30px;
          margin: 0;

          @include mobile {
            font-family: 'raleway-black';
            font-size: 22px;
            text-align: center;
          }

        }

        .nombre {
          span {
            text-transform: capitalize;

            @include mobile {
              font-family: 'raleway-semi-bold';
              font-size: 22px;
            }
          }
        }

        .puesto {
          margin: 5px 0;

          @include mobile {
            font-family: 'raleway-regular';
            font-size: 18px;
            text-align: center;
          }
        }

        .separador {
          width: 80%;
          height: 2px;
          margin-top: 15px;
          border-bottom: 2px dotted $azul-claro;
        }

        .gustos {

          .gusto {

            font-size: 17px;
            margin: 6px 0;

            @include mobile {
              font-size: 16px;
            }

            span {
              font-family: 'raleway-bold';
            }
          }
        }
      }
    }

    .perfiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;
      padding: 0 6%;
      gap: 58px;
      row-gap: 30px;
      padding-bottom: 50px;
    }

    .carrCompaneros {
      padding: 15px 0 50px;

      .swiper {
        padding: 0 30px;

        .swiper-slide {
          padding: 0 0px;
        }
      }

      @include desktop-min {
        display: none;
      }
    }

    .divCompaneros {
      @include mobile {
        display: none;
      }
    }

    .mini_perfil {
      width: 160px;
      color: $blue-font;

      @include mobile {
        width: 100px;
        height: 140px;
      }

      .div_foto {
        margin-left: 5px;
        width: 95%;
        height: 140px;
        background-image: url('../../images/bubble_back_person.svg');
        background-repeat: no-repeat;
        background-position: center;
        filter: grayscale(100);
        transition: all .3s ease-in;
        cursor: pointer;

        @include mobile {
          height: auto;
          background-size: contain;
        }

        &.active {
          filter: grayscale(0%);
        }

        &:hover {
          filter: grayscale(0%);
        }

        img {
          margin-top: -5px;
          display: block;
          width: 85%;
          margin: 0 auto;
        }
      }

      .nombre {
        text-align: center;
        font-family: 'raleway-extra-bold';
        font-size: 17px;
        margin: 0;

        @include mobile {
          font-size: 14px;
        }
      }

      .cargo {
        text-align: center;
        font-size: 11px;
        padding: 0 10px;
        margin: 0;
      }
    }
  }

  .div7 {
    display: flex;
    width: 100%;
    height: 602px;
    padding: 0 0 0 0;
    background-color: $gray_back_video;

    @include mobile {
      height: 234px;
    }

    .div_icon {
      width: 195px;
      height: 195px;
      margin: auto;

      @include mobile {
        height: 67px;
        width: 67px;
      }

      .icon {
        width: 100%;
        height: 100%;
        background-color: $blanco;
      }
    }

  }