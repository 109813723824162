    .card_blog {
      position: relative;
      width: 300px;
      min-height: 390px;
      height: max-content;
      background-color: #FFF;
      box-shadow: 10px 10px 20px #00000029;
      border-radius: 20px;
      overflow: hidden;

      .head_card {
        position: relative;
        width: 100%;
        height: 184px;

        figure {
          height: 100%;

          .figure-bkp {

            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }


      }

      .body_card {

        padding: 12px 17px 40px;

        .subtitle_blog {
          text-align: center;
          font-family: 'raleway-bold';
          font-size: 20px;
          color: $blue-font;
          margin-bottom: 1rem;
        }

        .pr_card,
        p {
          margin: 0;
          font-family: 'raleway-regular';
          font-size: 15px;
          color: $gray1;
          max-height: 9rem;
          overflow: hidden;
          word-break: keep-all;
          -ms-word-break: keep-all;

          span {
            font-family: 'raleway-bold';
          }

          &.texto {
            margin: 10px 0 20px 0;
          }
        }

        p {
          margin: 1rem 0;
        }

        .boton_white {
          position: absolute;
          bottom: 1rem;
          right: 50%;
          transform: translate(50%);
          width: 107px;
          display: block;
          margin: 0 auto;
        }
      }

    }

    .pageArticle,
    .pageBLog {
      position: relative;

      .Socialfloat {
        position: fixed;
        right: 0;
        bottom: 30px;
        width: max-content;
        background-color: rgba(25, 211, 224, 0.1607843137);
        border-radius: 20px;
        padding: 10px;
        z-index: 1;

        @include mobile {
          position: absolute;
          right: 50%;
          bottom: 0px;
          transform: translateX(50%);
        }
      }

      .social_info {
        width: 180px;
        text-align: center;

        @include mobile {
          margin: 10px 0 0;
          width: 100%;
          display: flex;
          gap: 2rem;
        }

        .social1,
        .social2 {
          width: 100%;

          h5 {
            font-size: 20px !important;
          }

          @include mobile {
            width: 49%;
          }
        }

        .social_networks {
          display: flex;
          gap: 10px;
          padding: 0 0 0 10px;
          margin-bottom: 15px;
          justify-content: center;

          .icon {
            background-repeat: no-repeat;
          }
        }

        .label_footer {
          width: 100%;
          position: relative;
          display: flex;
          height: 27px;
          border: 1px solid $azul-medio;
          border-radius: 10px;

          top: -10px;

          @include mobile {
            width: 100%;
            top: 0;
            left: 0;
          }

          input {
            background-color: transparent;
            border: none;
            border-radius: 10px;
            padding: 0 20px 0 25px;
            flex: 1;
            color: $blue-font;
            font-size: 17px;

            &:focus {
              outline: none;
              background-color: $azul-claro ;
            }

            &::placeholder {
              font-family: 'raleway-medium';
              font-size: 17px;
              color: $blanco;
            }
          }

          .icon {
            background-color: $azul-medio;
            width: 18px;
            height: 17px;
            position: absolute;
            top: 4px;
            left: 5px;
          }

          .icon-send {
            background-color: $azul-medio;
            width: 13px;
            height: 13px;
            top: 7px;
            left: 92%;

            @include mobile {
              left: 88%;
            }
          }
        }
      }
    }

    .div9 {
      width: 100%;
      height: auto;
      min-height: calc(100vh - 98px);
      padding: 50px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding: 40px 0 90px 0;
        // margin-bottom: 40px;
      }



      .titulo {
        margin: 0 auto;
        width: 180px;

        @include mobile {
          width: 82px;
        }
      }

      .flex {
        padding: 50px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 50px;
      }
    }

    .butons_pagination {
      // width: 50%;
      margin: 0 auto 40px;
      display: flex;
      justify-content: flex-end;
      gap: 30px;
      align-items: center;
      margin-right: 20%;


      .current_p,
      .button_page {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000540;
        font-weight: bold;
        box-shadow: 6px 6px 12px #00000029;
        width: 30px;
        height: 30px;
        background-color: #FFFFFF;
        border-radius: 20px;
        cursor: pointer;

        .icon {
          width: 100%;
          height: 100%;
          background-color: $purpura;
        }

        &:hover {
          transform: scale(1.05);
        }
      }

      .current_p {
        font-family: 'raleway-regular';
        font-size: 16px;
        color: $purpura;
      }
    }