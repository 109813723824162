@import '../../utilities/mixins.scss';
@import '../../utilities/variable.scss';

.card_servicios {
  box-sizing: border-box;
  width: 280px;
  height: 358px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 5px 5px 15px #00000029;
  border-radius: 30px;
  padding: 20px;
  margin: 0 auto;

  .img_card {
    width: 230px;
    height: 220px;
    margin: auto;

  }

  .prf_card {
    text-align: center;
    font-size: 20px;
    color: $blue-font;
    padding: 0 15px;

    @include mobile {
      font-size: 17px;
    }
  }

  .boton {
    width: 94px;
    height: 29px;
    padding: 0 6px;
  }
}