.terminos {
  padding: 0 10% 20px;

  .img_term {
    width: 100%;
    height: 68px;
    margin: 35px 0 0 0;
  }

  .terminos_img {
    background: url('../../images/title_terminos.svg') center center / contain no-repeat;

    &_ing {
      background: url('../../images/title_terminos_ing.png') center center / contain no-repeat;
    }

    @include mobile {
      height: 75px;
      background: url('../../images/title_terminos_movile.png') center center / contain no-repeat;

      &_ing {
        background: url('../../images/title_terminos_movile_ing.png') center center / contain no-repeat;
      }
    }
  }

  .terminos_priv {
    width: 110%;
    margin-left: -5%;
    background: url('../../images/title_privacidad.png') center center / contain no-repeat;

    &_ing {
      width: 110%;
      margin-left: -5%;
      background: url('../../images/title_privacidad_ing.png') center center / contain no-repeat;
    }

    @include mobile {
      background: url('../../images/title_privacidad_movile.png') center center / contain no-repeat;

      &_ing {
        background: url('../../images/title_privacidad_movile_ing.png') center center / contain no-repeat;
      }
    }
  }

  .terminos_habeas {
    width: 65%;
    min-width: 750px;
    margin: 30px auto 0;
    background: url('../../images/title_habeas.png') center center / contain no-repeat;

    &_ing {
      width: 65%;
      min-width: 750px;
      margin: 30px auto 0;
      background: url('../../images/title_habeas_ing.png') center center / contain no-repeat;
    }

    @include mobile {
      min-width: 90%;
      height: 85px;
      background: url('../../images/title_habeas_movile.png') center center / contain no-repeat;

      &_ing {
        min-width: 90%;
        height: 85px;
        background: url('../../images/title_habeas_movile_ing.png') center center / contain no-repeat;
      }
    }
  }

  h3 {
    color: #7519ff;
    font-size: 30px;

    @include mobile {
      font-size: 25px;

    }
  }

  h2 {
    text-align: center;
    display: block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
  }

  h2:before {
    content: '';
    display: block;
    width: 100%;
    height: 95%;
    background: linear-gradient(180deg, rgba(25, 211, 224, 1) 0%, rgba(71, 93, 241, 1) 35%, #7519ff 100%);
    mix-blend-mode: screen; //<-- atento a esta linea//
    position: absolute;
    top: 1px;
    left: 0;
  }

  p {
    margin: 15px 0;
  }

  .dir {
    background-color: rgba(25, 211, 224, 1);
  }

  .fecha {
    color: #7519ff;
  }
}