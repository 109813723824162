.LandingPauta {
  min-height: calc(100vh - 291px);

  .carrusel_landing {
    height: 50rem;

    @include mobile {
      height: 49.3rem;
    }

    .slick-prev,
    .slick-next {
      z-index: 1;
      width: 50px;
      height: 50px;
      background: #FFFFFF50;
    }

    .slick-prev {
      left: 10px;
    }

    .slick-next {
      right: 10px;
    }


    .slick-track {
      display: block;
      padding: 0px;
    }

  }

  .bannerLandingPauta {
    position: relative;
    height: 500px;
    background: url('../../images/landings/ArteBannerLanding2KTS.png') center/cover no-repeat;

    @include mobile {
      background: url('../../images/landings/ArteBannerLanding2KTS_mb.png') center/cover no-repeat;
      height: 493px;
    }

    ;

    .text_banner {
      position: absolute;
      top: 25%;
      right: 10.9%;
      width: 35%;
      line-height: 1.21;
      text-align: center;

      @include mobile {
        top: 7%;
        left: 50%;
        width: 85%;
        transform: translateX(-50%);
        line-height: 1.15;
      }
    }

    p {
      margin: 0 0 27px;
      color: $blanco;
      font-size: 45px;
      font-family: $fontRm;

      @include mobile {
        margin: 0;
        font-size: 30px;
      }
    }

    b {
      font-family: $fontLG;
    }
  }

  .testimon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0 0 0;
    gap: 10px;
    text-align: center;



    figure {
      max-width: 545px;

      img {
        display: block
      }

      ;
    }

    .section_text {
      max-width: 500px;
      margin: -5px 0 0 0;
      padding: 0 6rem 0 0rem;

      h3 {
        margin: 0 0 10px 0;
        font-family: $fontLG;
        font-size: 3.5rem;
        font-weight: normal;
        color: $blue-font;
      }

      p {
        font-size: 3rem;
        margin: 0 0 30px 0;
        letter-spacing: -0.18px;
      }

      .div_btn {
        .boton_gradient {
          margin: 0 auto;
          padding: 0 30px;
        }
      }

      @include mobile {
        padding: 0;

        h3 {
          font-size: 3rem;
          margin: 0 0 13px 0;
        }

        p {
          font-size: 2rem;
          padding: 0 10%;
          line-height: 1.25;
          margin: 0 0 20px;
        }

        .div_btn {
          .boton_gradient {
            margin: 0 auto;
            padding: 0 20px;
          }
        }
      }
    }

    @include mobile {
      flex-direction: column-reverse;
      padding: 35px 8% 0;
      gap: 12px;

      figure {
        padding: 0 22.5px;
      }
    }
  }

  .beneficios {
    text-align: center;
    background: #FFFFFF;
    padding: 5.3rem 0 5rem;

    @include mobile {
      padding: 3.3rem 5rem 3.1rem;
    }

    h1 {
      margin: 0 0 45px;
      font-family: $fontLG;
      font-weight: normal;
      font-size: 35px;
      color: $blue-font2;
      padding: 0 25%;

      @include mobile {
        margin: 0 0 35px;
        padding: 0;
        line-height: 1.18;
        font-size: 3rem;
      }
    }

    .benefits_list {
      display: flex;
      justify-content: center;
      gap: 2.5rem;
      margin: 0 0 4rem;
      flex-wrap: wrap;

      @include mobile {
        // flex-direction: column;
        gap: 3.2rem;
        margin: 0 0 3.1rem;
      }
    }

    .benefit_card {
      width: 39.7rem;
      height: 37.7rem;
      padding: 3.2rem 0 3rem;
      font-family: $fontRr;
      border-radius: 3rem;
      box-shadow: $shadow2;

      @include mobile {
        width: 100%;
        max-width: 34rem;
        height: 25rem;
      }

      figure {
        width: 90%;
        margin: -1px auto 0;

        img {}

        @include mobile {
          width: 92%;
          margin: -20px auto 0;
        }
      }

      p {
        font-size: 3rem;
        color: $blue-font2;
        margin: 3px 0 0 0;

        @include mobile {
          font-size: 2rem;
          margin: -13px 0 0 0;
          padding: 0 5%;
        }
      }

      &.cardN1 {

        figure {
          width: 63%;
          margin: -11px auto 0;
        }

        p {
          margin: 17px 0 0;
        }

        @include mobile {
          figure {
            width: 57%;
            margin: -27px auto 0;
          }

          p {
            margin: 6px 0 0;
            padding: 0 10%;
          }
        }
      }

      &.cardN2 {
        figure {
          width: 65%;
          margin: -24px auto 0;
        }

        p {
          margin: 6px 0 0;
        }

        @include mobile {
          figure {
            width: 56%;
            margin: -28.5px auto 0;
          }

          p {
            margin: 0px 0 0;
            padding: 0 10%;
          }
        }
      }

      &.cardN3 {}
    }

    .div_btn {
      width: max-content;
      margin: auto;

      .boton_gradient {
        padding: 0 30px;

        @include mobile {
          padding: 0 40px;
        }
      }
    }
  }

  .contact_sec {

    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;
    gap: 18px;

    @include mobile {
      padding: 31px 0 35px;
      align-items: center;
      flex-direction: column;
      gap: 0;
    }

    figure {
      width: 345px;

      img {
        display: block;
        margin-left: -32px;
      }

      @include mobile {
        width: 65%;

        img {
          margin: 0 0 41px;
        }
      }
    }

    .section_form {
      padding: 40px 0 0 0;
      max-width: 425px;

      @include mobile {
        padding: 0 11%;
        width: 100%;
      }

    }

    .text_form {
      margin: 0 0 2rem;
      padding: 0 0 0 15px;
      text-align: center;
      font-family: $fontRb;
      font-size: 3rem;
      color: $blue-font;

      @include mobile {
        padding: 0 10%;
        font-size: 2.5rem;
        margin: 0 0 .5rem;
      }
    }

    .form_contact {
      padding: 11px 10px 10px 20px;
      display: flex;
      flex-direction: column;
      gap: 31px;


      @include mobile {
        width: 100%;
        padding: 0;
        gap: 31px;
        margin-bottom: 13px;
      }

      .icon {
        width: 19px;
        height: 18px;
        background-color: $blue-font;

        &.icon-location_map {
          width: 20px;
          height: 23px;
        }
      }

      .row {
        display: flex;
        width: 100%;

        @include mobile {
          flex-wrap: wrap;
        }

        .zipcod {
          width: 40%;

          .input_contact {
            padding-right: 10px;
          }

          @include mobile {
            width: 100%;
            margin-bottom: 3rem;
          }
        }

        .ext {
          width: 90px;

          select {
            padding-right: 10px;
          }
        }

        .teleph {
          width: 40%;

          .input_contact {
            margin: 0 0 0 10px;

          }

          @include mobile {
            width: 71%;
            flex: 1 1 71%;
          }
        }
      }

      .label_contact {
        position: relative;
        display: flex;
        height: 27px;

        .input_contact {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid $blue-font;
          margin-left: 35px;
          border-radius: 5px 5px 0 0;
          padding: 0 10px 0 5px;
          flex: 1;
          color: $blue-font;
          font-size: 17px;

          &:focus {
            outline: none;
            background-color: #7519ff50;
          }

          &::placeholder {
            font-family: $fontRU;
            font-size: 17px;
            color: $blue-font;

            @include mobile {
              font-size: 15px;
            }
          }

          // &:valid {
          //   background-color: transparent;
          //   @include mobile {}
          // }
        }

        .select.input_contact {
          color: $blue-font;

          &::-webkit-scrollbar {
            display: none;
          }

          .option {
            background-color: #19d3e070;
          }
        }

        .icon {
          position: absolute;
          top: 5px;
          left: 5px;
        }

        .icon-caretUp,
        .icon-caretDown {
          cursor: pointer;
          width: 7px;
          height: 7px;
          background-color: $blue-font;
          top: 70%;
          left: 90%;
        }

        .icon-caretUp {
          top: 20%;
        }
      }

      .boton {
        width: 135px;
        display: block;
        margin: 5px auto 0;
        font-size: 20px;

        @include mobile {
          margin: 0 auto;
        }
      }

      .sendOk {
        margin-top: 0px;
        background-color: #00FF0040;
        border-radius: 15PX;
        padding: 10px 5px;
        text-align: center;
      }

    }
  }
}