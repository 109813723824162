.NOT_FOUND {
  position: relative;
  min-height: calc(100vh - 98px);
  background: url('../../images/bckg_404.png') center/cover no-repeat;
  // padding: 17.85% 0 0 11.65%;
  color: $blanco;

  .div_text {
    position: absolute;
    top: 35%;
    left: 11.65%;
    width: max-content;
    text-align: center;
  }

  h3 {
    font-family: $fontRb;
    font-size: 8.7rem;
    margin: 0;
  }

  h1 {
    margin: 9px 0 3.7rem;
    font-family: $fontRr;
    font-size: 3rem;
    font-weight: 400;
  }

  .button_not_found {
    display: block;
    width: 220px;
    height: 40px;
    margin: auto;
    font-family: $fontRb;
    font-size: 20px;
    color: $azul-medio;
    background-color: $blanco;
    box-shadow: $shadow4;
    border-radius: 20px;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  @include mobile {
    min-height: calc(100vh - 80px);
    background: url('../../images/bckg_404_mobile.png') center/cover no-repeat;

    .div_text {
      top: 50.4%;
      left: 0;
      width: 100%;
    }

    h3 {
      font-size: 7.5rem;
    }

    h1 {
      margin: 4px 0 2.9rem;
      padding: 0;
      font-size: 2.5rem;
    }
  }
}