// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Candara';
  src: url('../../fonts/CANDARA.ttf');
}

@font-face {
  font-family: 'quicksand-regular';
  src: url('../../fonts/Quicksand-Regular_1.woff');
}

@font-face {
  font-family: 'quicksand-bold';
  src: url('../../fonts/Quicksand-Bold.woff');
}

@font-face {
  font-family: 'product-sans';
  src: url('../../fonts/ProductSans.ttf');
}

@font-face {
  font-family: 'product-sans-bold';
  src: url('../../fonts/ProductSansBold.ttf');
}

@font-face {
  font-family: 'raleway-light';
  src: url('../../fonts/Raleway-Light.ttf');
}

@font-face {
  font-family: 'raleway-regular';
  src: url('../../fonts/Raleway-Regular.ttf');
}

@font-face {
  font-family: 'raleway-medium';
  src: url('../../fonts/Raleway-Medium.ttf');
}

@font-face {
  font-family: 'raleway-bold';
  src: url('../../fonts/Raleway-Bold.ttf');
}

@font-face {
  font-family: 'raleway-semi-bold';
  src: url('../../fonts/Raleway-SemiBold.ttf');
}

@font-face {
  font-family: 'raleway-extra-bold';
  src: url('../../fonts/Raleway-ExtraBold.ttf');
}

@font-face {
  font-family: 'raleway-black';
  src: url('../../fonts/Raleway-Black.ttf');
}

// font-family: 'Luckiest Guy',cursive;
// @import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
//font-family: 'Rubik',sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Rubik:wght@300;400;600;700;900&display=swap');