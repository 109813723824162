.home {
  position: relative;
  overflow-x: hidden;

  .contenedor_home {
    max-width: 1440px;
    min-height: calc(100vh - 68px);
    margin: 0 auto;

    // background: url('../../images/paper-texture.png') center/ contain repeat;
    @include mobile {
      min-height: calc(100vh - 50px);
    }
  }

  .div1 {
    width: 100%;
    height: 452px;
    padding: 148px 0 0 0;
    background: url('../../images/back_div1.png') center bottom /cover no-repeat;

    @include mobile {
      background: url('../../images/back_init_mobile.png') center bottom /cover no-repeat;
      height: 680px;
      padding: 194px 5% 0;
    }

    .leyenda {
      width: 520px;
      text-align: center;
      margin: 0 0 0 22.5%;

      @include mobile {
        width: 100%;
        margin: 0;
      }
    }

    .boton {
      margin-top: 15px;
      width: 172px;
      height: 33px;

      @include mobile {
        width: 152px;
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }

  .div2 {
    width: 100%;
    height: 487px;
    padding: 20px 9.5% 0 10.9%;

    @include mobile {
      padding: 25px 0 0 0;
      height: 514px;
    }

    .subtitle {
      font-family: 'raleway-medium';
      padding: 0 16% 0 14.2%;
      text-align: center;

      @include mobile {
        font-size: 18px;
        padding: 0 8%;

      }
    }

    .pasos {
      display: flex;
      justify-content: space-between;
      margin-top: -16px;

      @include mobile {
        margin-top: -30px;
      }

      &.div_pasos {
        @include mobile {
          display: none;
        }
      }

      &.carru_pasos {
        @include desktop-min {
          display: none;
        }
      }

      .paso {
        position: relative;
        width: 263px;

        @include mobile {
          display: flex;
          flex-direction: column-reverse;
          margin: auto;
        }

        &::after {
          content: '';
          width: 70%;
          height: 2px;
          right: 0;
          top: 43%;
          position: absolute;
          border-bottom: 2px dotted $blue-font;
          transform: translate(82%);
        }

        &:first-of-type {
          &::after {
            width: 80%;
          }
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }

        .div_img {
          height: 246px;
          width: 100%;
        }

        .description {
          display: flex;
          align-items: center;
          padding: 0 0 0 21px;
          gap: 33px;

          @include mobile {
            // padding: 0 15%;
            padding: 0 10%;
            gap: 5px;
          }
        }

        .num {
          min-width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $purpura;
          border-radius: 50%;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          font-size: 25px;
          font-weight: bold;
          line-height: 25px;
          color: $azul-medio;

          span {
            margin-bottom: 5px;
          }
        }

        .prf_paso {
          text-align: center;
        }

        &:nth-of-type(2) {
          width: 315px;

          .div_img {
            height: 274px;
            margin-top: -29px;
            transform: translateX(-10px);
          }

          .description {
            padding: 0 24px 0 0;
            margin: 0px 0 0px -10px;

            .prf_paso {
              margin: 10px 0 10px -15px;
            }
          }
        }

        &:nth-of-type(3) {
          .description {
            // padding: 0px 59px 0 49px;
            padding: 0px 40px 0 49px;


            .prf_paso {
              margin: 10px 0 10px -20px;
            }

          }
        }
      }
    }

    .boton {
      margin: 18px auto 0;
      width: 254px;
      font-size: 20px;

      @include mobile {
        font-size: 14px;
        width: 160px;
        height: 35px;
        margin: 0 auto;
      }
    }
  }

  .div3 {
    position: relative;
    width: 100%;
    height: 450px;
    background-color: $purp_back_10;

    @include mobile {
      height: 514px;
    }

    .bu1,
    .bu2 {
      top: 0px;
      position: absolute;
      height: 450px;
      background-repeat: no-repeat;
      z-index: 0;

      @include mobile {
        height: 220px;
        width: 190px;
      }
    }

    .bu1 {
      left: 0px;
      background-image: url('../../images/buble+10_1.svg');
      background-position: -271px -38px;
      width: 310px;

      @include mobile {
        background-image: url('../../images/10-bl.svg');
        width: 190px;
        top: 56%;
        height: 230px;
        background-size: 200%;
        background-position: -150px -22px;
      }
    }

    .bu2 {
      right: 0px;
      background-image: url('../../images/buble+10_2.svg');
      background-position: -84px -65px;
      width: 231px;

      @include mobile {
        background-image: url('../../images/10-tr.svg');
        width: 190px;
        top: 0%;
        background-size: 167%;
        background-position: -23px -135px;
      }
    }

    .content {
      position: relative;
      padding: 0 15%;
      display: flex;
      height: 100%;

      @include mobile {
        display: flex;
        flex-direction: column;
        padding: 120px 10% 0;
      }

      .jet {
        position: relative;
        width: 35%;
        z-index: 0;

        img {
          width: 140%;
          margin-left: 23px;
          margin-top: -24px;
        }

        @include mobile {
          width: 37%;
          max-width: 210px;
          position: absolute;
          top: 16px;
          left: 5px;

          img {
            margin: 0;

          }
        }
      }


      .years {
        width: 65%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: -16px;

        @include mobile {
          width: 100%;
        }

        .diez {
          width: 46%;

          @include mobile {
            width: 50%;
            align-self: flex-end;
            margin-right: 30px;
            margin-bottom: 30px;
          }
        }

        .prf_10 {
          padding: 0 8% 0 10%;
          text-align: center;

          @include mobile {
            padding: 0 5%;
            line-height: 1.3;
          }
        }

        .boton {
          margin: 30px auto 0;
          width: 302px;
          font-size: 20px;

          @include mobile {
            width: 208px;
            height: 35px;
            font-size: 14px;
            margin: 25px auto 0;
            padding: 0 10px;
          }
        }


      }
    }
  }

  .div4 {
    width: 100%;
    height: 661px;
    padding: 75px 0 0 0;

    &.carrServicios {
      @include mobile {
        display: none;
      }
    }

    &.divServicios {
      padding: 60px 0 0 0;
      height: auto;

      .servicios {
        display: flex;
        flex-direction: column;
        gap: 50px;

        .dn {
          display: none;

          &.ok {
            display: flex;
          }
        }
      }

      .boton_white {
        display: block;
        font-size: 14px;
        margin: 35px auto;
      }

      @include desktop-min {
        display: none;
      }
    }

    .titulo {
      margin: 0px 23% 28px;
      // padding: 0 20%;
    }

    .carrusell {
      padding: 15px 5%;

      .swiper {
        padding: 0 50px;


        .swiper-wrapper {
          box-sizing: border-box;
          padding: 20px 0 30px;

          .swiper-slide {
            // padding: 0 63px;
            padding: 0 4%;
          }
        }

        .swiper-button-prev,
        .swiper-button-next {
          left: 3px;
          right: auto;
          width: 57px;
          height: 57px;
          border-radius: 50%;
          background: #FFFFFF;
          box-shadow: 3px 3px 6px #00000029;
          opacity: 1;

          &::after {
            content: url('../../images/CaretLeft.svg') !important;
          }

        }

        .swiper-button-next {
          left: auto;
          right: 3px;

          &::after {
            content: url('../../images/CaretRight.svg') !important;
          }

        }

        .swiper-button-disabled {
          opacity: 0.35;
        }


      }
    }


    .card_servicios {
      box-sizing: border-box;
      width: 280px;
      height: 358px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #FFFFFF;
      box-shadow: 5px 5px 15px #00000029;
      border-radius: 30px;
      padding: 20px;
      margin: 0 auto;

      .img_card {
        width: 230px;
        height: 220px;
        margin: auto;

      }

      .prf_card {
        text-align: center;
        font-size: 20px;
        color: $blue-font;
        padding: 0 15px;

        @include mobile {
          font-size: 17px;
        }
      }

      .boton {
        width: 94px;
        height: 29px;
        padding: 0 6px;
      }
    }

  }

  .div5 {

    width: 100%;
    min-height: 850px;
    padding: 70px 0 0 0;
    background-color: $blue_back_aun;

    @include mobile {
      padding: 60px 0;
    }

    &.divPrices {
      @include mobile {
        display: none;
      }
    }

    &.carrPrices {
      min-height: auto;

      .carrusell {
        margin-top: -20px;
      }

      .carrusell .swiper .swiper-wrapper {
        box-sizing: border-box;
        padding: 19px 0 21px;
      }

      .swiper-slide {
        padding: 0;
      }


      .dn {
        display: none;

        &.ok {
          display: block;
        }
      }

      @include desktop-min {
        display: none;
      }
    }

    .titulo {
      margin: 0px 14% 16px;

      @include mobile {
        margin: 0px 21% 10px;
      }
    }

    .subtitle {
      margin: 0;
      font-size: 30px;
      text-align: center;
      margin-bottom: 44px;

      @include mobile {
        font-size: 20px;
        margin-bottom: 0;
      }
    }

    .planes {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;

      .plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 280px;
        height: 512px;
        background: url('../../images/price1.svg') 0% 0% / cover no-repeat;
        color: $blue-font;
        margin-left: -7px;

        .medalla {
          display: flex;
          justify-content: center;
          margin-bottom: 18px;


          .icon {
            width: 120px;
            height: 120px;
          }
        }

        .name_plan {
          font-family: 'raleway-black';
          font-size: 20px;
          margin-bottom: 15px;
        }

        .price_plan {
          display: flex;
          align-items: flex-start;
          font-family: 'raleway-semi-bold';
          font-size: 20px;
          margin-bottom: 59px;
          margin-left: 10px;


          span {
            font-family: 'raleway-bold';
            font-size: 51px;
            line-height: 30px;
          }
        }

        .serv_plan {
          padding: 0 12% 0 14%;
          margin-bottom: 40px;


          .flex {

            align-items: center;
            gap: 5px;

            .icon_s {
              display: grid;
              grid-template-columns: auto 9px;
              grid-template-rows: 1fr;

              .icon {
                width: 28px;
                height: 28px;
                background-color: $azul-claro;
              }

              .icon-smallFace,
              .icon-smallGoogle,
              .icon-smallTiktok,
              .icon-smallInsta {
                width: 8.5px;
                height: 8.5px;
              }

            }

            .p_serv {
              font-family: 'raleway-regular';
              font-size: 15px;
              color: $blanco;
            }

          }
        }

        .boton_blue {}
      }

      .plan_platino {
        .name_plan {}

        .price_plan {
          margin-bottom: 50px;
        }

        .serv_plan {
          margin-bottom: 30px;

          .flex {
            gap: 10px;
          }
        }
      }

      .plan_oro {
        .name_plan {}

        .price_plan {
          margin-bottom: 40px;
        }

        .serv_plan {
          padding: 2px 11% 0 16%;
          margin-bottom: 8px;


          .flex {
            align-items: center;
            margin-bottom: 9px;
            gap: 12px;

            .p_serv {
              margin: 0;
            }

          }
        }
      }

      .plan_medida {
        background: url('../../images/price2.svg') 0% 0% / cover no-repeat;

        .name_plan {
          margin-bottom: 46px;
        }

        .serv_plan {
          padding: 25% 20%;
          margin-bottom: 13px;

          .flex {
            .p_serv {
              text-align: center;
            }

          }
        }
      }
    }

    .terminos_planes {
      padding: 20px 10.2%;

      .sub {
        margin: 41px 0 0;
        color: $blue-font;
        font-size: 20px;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: $purpura;
        }

        @include mobile {
          color: $azul-medio;
          font-size: 18px;
          text-align: center;
          margin: 0 0 0 0;
          cursor: pointer;


        }
      }

      .prf_term {
        margin: 5px 0;
        color: $gray1;
        line-height: 1.3;
        display: none;
        font-size: 18px;

        li {
          display: flex;
          align-items: center;
          height: 30px;
          gap: 1rem;
        }

        li::marker {
          // display: none;
          // display: block;
          // min-width: 10px;
          // color: $azul-medio;
          // content: url('../../images/Award.svg');
          // margin-bottom: -10px;
        }

        li::before {
          display: block;
          min-width: 10px;
          height: 30px;
          content: url('../../images/Award.svg');
        }
      }

      .block {
        display: block;
      }
    }
  }

  .div6 {
    position: relative;
    width: 100%;
    min-height: 1008px;
    padding: 64px 0 0 0;
    background-color: $blue_back_equipo;

    @include mobile {
      min-height: auto;
      padding: 67px 0 0 0;
    }

    .bu1,
    .bu2,
    .bu3,
    .bu4 {
      width: 36.7%;
      height: 250px;
      bottom: 0px;
      position: absolute;
      background-repeat: no-repeat;
      z-index: 0;

      @include mobile {
        display: none;
      }
    }

    .bu1,
    .bu2 {
      top: 0px;
    }

    .bu5,
    .bu6 {
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 92px;
      z-index: 0;
      background-size: contain;

      @include desktop-min {
        display: none;
      }
    }

    .bu1 {
      left: 0px;
      background-image: url('../../images/buble_nEquip_1.svg');
      background-position: -97px -90px;
    }

    .bu2 {
      right: 0px;
      background-image: url('../../images/buble_nEquip_2.svg');
      background-position: -2px -52px;
    }

    .bu3 {
      left: 0px;
      background-image: url('../../images/buble_nEquip_3.svg');
      background-position: -145px 79px;
    }

    .bu4 {
      right: 0px;
      background-image: url('../../images/equipo_4.svg');
      background-position: 16px 97px;
    }

    .bu5 {
      top: 0px;
      background-image: url('../../images/bub1_nuest_equip.svg');
    }

    .bu6 {
      bottom: 0px;
      background-image: url('../../images/bub2_nuest_equip.svg');
    }

    .titulo {
      margin: 0px 28% 13px;

      @include mobile {
        margin: 0 19% 13px;
      }
    }

    .perfil {
      display: flex;
      justify-content: center;
      width: 70%;
      margin: auto;

      @include mobile {
        flex-direction: column;
        // width: 100%;
      }

      .imagen_colaborador {
        width: 45%;
        height: 350px;
        background-image: url('../../images/bubble_back_person.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 85% 85%;

        @include mobile {
          width: 80%;
          height: auto;
          margin: 0 auto 2px;
        }


        img {
          display: block;
          width: 80%;
          margin: -6px auto 0px;
          // height: 350px;
        }
      }

      .info_colaborador {
        width: 37%;
        color: $blue-font;
        padding: 67px 0 0 18px;

        @include mobile {
          padding: 0;
          width: 87%;
          margin: 0 auto;
        }

        .nombre,
        .saludo {
          text-transform: uppercase;
          font-family: 'raleway-extra-bold';
          font-size: 30px;
          margin: 0;

          @include mobile {
            font-family: 'raleway-black';
            font-size: 22px;
            text-align: center;
          }

        }

        .nombre {
          span {
            text-transform: capitalize;

            @include mobile {
              font-family: 'raleway-semi-bold';
              font-size: 22px;
            }
          }
        }

        .puesto {
          margin: 5px 0;

          @include mobile {
            font-family: 'raleway-regular';
            font-size: 18px;
            text-align: center;
          }
        }

        .separador {
          width: 80%;
          height: 2px;
          margin-top: 15px;
          border-bottom: 2px dotted $azul-claro;
        }

        .gustos {

          .gusto {

            font-size: 17px;
            margin: 6px 0;

            @include mobile {
              font-size: 16px;
            }

            span {
              font-family: 'raleway-bold';
            }
          }
        }
      }
    }

    .perfiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;
      padding: 0 6%;
      gap: 58px;
      row-gap: 30px;
    }

    .carrCompaneros {
      padding: 15px 0;

      .swiper {
        padding: 0 30px;

        .swiper-slide {
          padding: 0 0px;
        }
      }

      @include desktop-min {
        display: none;
      }
    }

    .divCompaneros {
      @include mobile {
        display: none;
      }
    }

    .mini_perfil {
      width: 160px;
      color: $blue-font;

      @include mobile {
        width: 100px;
        height: 140px;
      }

      .div_foto {
        margin-left: 5px;
        width: 95%;
        height: 140px;
        background-image: url('../../images/bubble_back_person.svg');
        background-repeat: no-repeat;
        background-position: center;
        filter: grayscale(100);
        transition: all .3s ease-in;
        cursor: pointer;

        @include mobile {
          height: auto;
          background-size: contain;
        }

        &.active {
          filter: grayscale(0%);
        }

        &:hover {
          filter: grayscale(0%);
        }


        img {
          margin-top: -5px;

        }
      }

      .nombre {
        text-align: center;
        font-family: 'raleway-extra-bold';
        font-size: 17px;
        margin: 0;

        @include mobile {
          font-size: 14px;
        }
      }

      .cargo {
        text-align: center;
        font-size: 11px;
        padding: 0 10px;
        margin: 0;
      }
    }
  }

  .div7 {
    display: flex;
    width: 100%;
    height: 602px;
    padding: 0 0 0 0;
    background-color: $gray_back_video;

    @include mobile {
      height: 234px;
    }

    .div_icon {
      width: 195px;
      height: 195px;
      margin: auto;

      @include mobile {
        height: 67px;
        width: 67px;
      }

      .icon {
        width: 100%;
        height: 100%;
        background-color: $blanco;
      }
    }

  }

  .div8 {
    // background: url('../../images/paper-texture.png') center /cover no-repeat;
    width: 100%;
    height: 621px;
    padding: 10px 0 0 0;
    position: relative;

    @include mobile {
      padding: 17px 0 0 0;
      height: auto;
    }

    .bu1,
    .bu2,
    .bu3,
    .bu4 {
      width: 16%;
      height: 190px;
      bottom: 0px;
      position: absolute;
      background-repeat: no-repeat;

      // background-size: contain;
      // z-index: -1;
      @include mobile {
        background-size: contain;
      }
    }

    .bu1,
    .bu2 {
      top: 0px;
    }

    .bu1 {
      left: 0px;
      background-image: url('../../images/bub_caso1.svg');
      background-position: -16px -39px;

      @include mobile {}
    }

    .bu2 {
      right: 0px;
      background-image: url('../../images/bub_caso2.svg');
      background-position: 3px -24px;

      @include mobile {
        background-position: 7px -2px;
        width: 26%;
      }
    }

    .bu3 {
      left: 0px;
      background-image: url('../../images/bub_caso3.svg');
      background-position: -9px 9px;

      @include mobile {
        background-position: -9px 90px;
      }
    }

    .bu4 {
      right: 0px;
      background-image: url('../../images/bub_caso4.svg');
      background-position: 15px 85px;

      @include mobile {
        background-position: 15px 138px;
        width: 25%;
      }
    }

    .titulo {
      margin: 0px 32% 25px;

      @include mobile {
        margin: 0px 28%;
      }
    }

    .success_stories {
      display: flex;
      justify-content: space-between;
      padding: 0 13%;
      margin-bottom: 22px;

      @include mobile {
        display: flex;
        flex-direction: column-reverse;
      }

    }

    .case {
      position: relative;
      color: $gray_light;
      width: 280px;
      text-align: center;

      @include mobile {

        margin: 0 auto;
      }


      &::after {
        position: absolute;
        top: 35%;
        left: 82%;
        content: '';
        height: 2px;
        width: 72%;
        border-bottom: 2px dotted $blue-font;

        @include mobile {
          display: none;
        }
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      .div_imag {
        padding: 10px 0 0 0;
        margin: 0 auto 8px;
        width: 125px;
        height: 100px;
        background: url('../../images/bubble_back_graysvg.svg') center / cover no-repeat;
        cursor: pointer;

        @include mobile {
          background: url('../../images/bubble_back_graysvg.svg') center / contain no-repeat;
          width: 76.5px;
          height: 59px;
          padding: 5px 0 0 0;
        }

        .icon {
          width: 60%;
          height: 90%;
          margin: 0 auto;
          background-color: $blanco;
          user-select: none;
        }

        .icon-desarrollo {
          width: 80%;
          height: 90%;

          @include mobile {
            width: 60%;
            margin: 5px auto 0;
          }
        }

        .icon-creacion {
          margin-top: 10px;
          width: 55%;
          height: 80%;

          @include mobile {
            width: 60%;
            margin: 5px auto 0;
          }
        }
      }

      .title_case {
        font-family: 'raleway-black';
        text-transform: uppercase;
        font-size: 20px;

        @include mobile {
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

      .subtitle_case {
        font-family: 'raleway-regular';
        text-transform: uppercase;
        font-size: 20px;

        @include mobile {
          font-size: 16px;
        }
      }

      &.active {
        .div_imag {
          background: url('../../images/bubble_back_purple.svg') center / cover no-repeat;

          @include mobile {
            background: url('../../images/bubble_back_purple.svg') center / contain no-repeat;
            width: 76.5px;
          }
        }

        .title_case,
        .subtitle_case {
          color: $blue-font;
        }
      }
    }

    .carrusell {
      // height: 275px;

      .swiper {
        .swiper-wrapper {
          padding: 20px 0;

          .swiper-slide {
            padding: 0 30px 0 41px;
          }
        }
      }
    }

    .steps_cases {
      @include mobile {
        display: none;
      }
    }

    .div_cases {
      padding: 15px 0 65px;

      .carrusell {
        padding: 0 5px;
      }

      .swiper {
        padding: 0 15px;

        .swiper-wrapper {

          .swiper-slide {
            padding: 0 15px;
          }
        }

        .swiper-horizontal>.swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal {
          bottom: 28px;
        }
      }

      @include desktop-min {
        display: none;
      }
    }

    .card_redes {
      width: 100%;
      height: 275px;
      display: flex;

      @include mobile {
        flex-direction: column;
        height: 515px;
        box-shadow: 3px 3px 6px #00000029;
        border-radius: 15px
      }

      .head_card {
        width: 188px;
        height: 100%;
        background-color: $back_compartir;
        padding: 39px 9px 15px;
        border-radius: 30px 0 0 30px;
        border-right: 2px dotted $blue-font;
        margin-right: 2%;

        @include mobile {
          background-color: $blue_back_card_small1;
          width: 100%;
          height: 145px;
          border: none;
          border-radius: 15px 15px 0 0;
          padding: 19px 15px 15px;
        }

        .img_card {
          position: relative;
          width: 65%;
          margin: 0 auto 25px;
          padding-bottom: 10px;

          @include mobile {
            width: 32%;
            height: auto;
            border: none;
            border-radius: 15px 15px 0 0;
            margin: 0 auto 15px;
          }

          &.img_price {
            @include mobile {
              width: 35%;
              padding-bottom: 0;
              margin-top: -10px;
            }
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 50%;
            height: 1px;
            width: 70%;
            background-color: $azul-claro;
            transform: translateX(-50%);
          }
        }

        .text_header {
          text-align: center;
          font-family: 'raleway-regular';
          font-size: 13px;
          color: $gray1;
        }
      }

      .body_card {
        display: flex;
        gap: 100px;

        @include mobile {
          flex-direction: column;
          gap: 0px;
        }

        .red {
          .tit_red {
            display: flex;
            justify-content: center;
            font-size: 17px;
            color: $blue-font;
            text-align: center;
            margin: 13px 0 8px;
            padding-bottom: 2px;

            span {
              position: relative;

              &::after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 50%;
                height: 1px;
                width: 60%;
                background-color: $azul-claro;
                transform: translateX(-50%);
              }
            }
          }

          &.face {
            height: 182px;

            .img_red {
              img {
                transform: translate(15px, -45px);

                @include mobile {
                  transform: translate(0px, -39px);
                }
              }
            }
          }

          &.insta {
            height: 182px;

            .tit_red {
              margin: 13px 0 0px;
            }

            .img_red {
              img {
                @include mobile {
                  display: block;
                  width: 60%;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }

    .card_web {
      position: relative;
      width: 100%;
      height: 275px;
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      background-color: $blue_back_card_big;
      border-radius: 30px;
      overflow: hidden;

      @include mobile {
        flex-direction: column;
        height: 481px;
        box-shadow: 3px 3px 6px #00000029;
        border-radius: 15px;
      }

      .bub {
        width: 40%;
        height: 100%;
        position: absolute;
        background: url('../../images/bubble_back_cellBlue.svg') center/cover no-repeat;
        top: 0;
        left: -20px;
        transform: rotateY(180deg);
        z-index: -1;

        @include mobile {
          transform: rotateY(0deg);
          background: url('../../images/bubb_web.svg') center/contain no-repeat;
          width: 100%;
          top: 103px;
          left: 0;
        }
      }

      .head_card {
        width: 40%;
        padding: 77px 4% 0 0;
        margin: 0px 0px 0 0;

        @include mobile {
          padding: 23px 0 0 0;
          width: 100%;
          height: 50%;
        }

        .img_card {
          position: relative;
          width: 150px;
          margin: 0 auto;
          padding-bottom: 15px;
          margin-bottom: 10px;

          @include mobile {

            margin-bottom: 27px;
          }

          &:after {
            content: '';
            width: 90px;
            background-color: $blue-font;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

          }

          img {}
        }

        .text_header {
          text-align: center;
          font-family: 'raleway-regular';
          font-size: 13px;
          color: $gray1;
          padding: 0px 18px;

          @include mobile {
            padding: 0px 25px;
            line-height: 1.4;
            font-size: 14px;
          }

          span {
            font-family: 'raleway-bold';
          }
        }
      }

      .body_card {
        width: 60%;
        display: flex;

        @include mobile {
          width: 100%;
          height: 50%;
        }

        .image {
          width: 81%;
          height: 95%;
          margin-left: auto;
          margin-right: 7%;
          margin-top: 10px;

          @include mobile {
            width: 100%;
            margin: 0;
          }
        }
      }

      &.emilan {
        .body_card {
          .image {
            background: url('../../images/Marcas/Web_emilan-01.png') 0% 39%/cover no-repeat;

            // transform: scale(.8);
            @include mobile {
              background: url('../../images/Marcas/Web_emilan-01.png') 69% center /118% no-repeat;

            }

          }
        }
      }

      &.directorio {

        .head_card {
          padding: 60px 4% 0 0;
          margin: 0 0 0 0;

          @include mobile {
            padding: 23px 0 0 0;
          }
        }

        .img_card {
          width: 253px;
          margin-bottom: 20px;

          @include mobile {
            margin-bottom: 32px;
          }

        }

        .text_header {
          padding: 0 23px;
        }

        .body_card {
          .image {
            background: url('../../images/Marcas/Web_Directorio.png') 10% 82%/cover no-repeat;

            @include mobile {
              background: url('../../images/Marcas/Web_Directorio.png') center 146%/120% no-repeat;
            }
          }
        }
      }

      &.nubi {

        .head_card {
          padding: 26px 4% 0 0;
          margin: 0 0 0 0;

          @include mobile {
            padding: 15px 0 0 0;
          }
        }

        .img_card {
          width: 190px;
          margin-bottom: 9px;

          @include mobile {
            padding-bottom: 10px;
            margin-bottom: 0px;
            width: 160px;
          }
        }

        .text_header {
          padding: 0 30px;

          @include mobile {
            padding: 0 15px;
          }
        }

        .body_card {
          .image {
            background: url('../../images/Marcas/Web_nuBi-01.png') 10% 44%/cover no-repeat;

            // transform: scale(0.9);
            @include mobile {
              background: url('../../images/Marcas/Web_nuBi-01.png') 89% center /118% no-repeat;

            }

          }
        }
      }

      &.agentes {

        .head_card {
          padding: 20px 4% 0 0;
          margin: 0 0 0 0;

          @include mobile {
            padding: 25px 0 0 0;
          }
        }

        .img_card {
          width: 217px;
          margin-bottom: 15px;

          @include mobile {
            padding-bottom: 10px;
            margin-bottom: 0px;
            width: 160px;
          }
        }

        .text_header {
          padding: 0 30px;
          line-height: 1.25;

          @include mobile {
            padding: 0 25px;
          }
        }

        .body_card {
          .image {

            background: url('../../images/Marcas/Web_Agentes.png') 10% 77%/cover no-repeat;

            @include mobile {
              background: url('../../images/Marcas/Web_Agentes.png') center 146%/120% no-repeat;

            }

          }
        }
      }
    }

    .card_marca {
      position: relative;
      width: 100%;
      display: flex;
      // align-items: center;
      background-color: $emilan_blue;
      justify-content: center;
      border-radius: 30px;
      overflow: hidden;

      @include mobile {
        flex-direction: column;
        height: 643px;
        box-shadow: 3px 3px 6px #00000029;
        border-radius: 15px;
      }

      .head_card {
        margin: 0px 0px 0 0;

        @include mobile {
          padding: 33px 0 0 0;
          width: 100%;
          height: 50%;
        }

        .img_card {
          position: relative;
          width: 150px;
          margin: 0 auto;
          margin-bottom: 0px;

          @include mobile {
            padding-bottom: 20px;
            margin-bottom: 40px;
          }

          &:after {
            content: '';
            width: 90px;
            background-color: $azul-claro;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

          }

          img {}
        }

        .text_header {
          text-align: center;
          font-family: 'raleway-regular';
          font-size: 13px;
          color: $blanco;
          padding: 0px 18px;

          @include mobile {
            padding: 0px 25px;
            line-height: 1.4;
            font-size: 14px;
          }

          span {
            font-family: 'raleway-bold';
          }
        }
      }

      .body_card {
        display: flex;

        @include mobile {
          width: 100%;
          height: 58%;
        }

        .image {
          @include mobile {
            width: 100%;
            height: 100%;
            margin: 0;
          }
        }
      }

      &.emilan {
        .body_card {
          .image {
            background: url('../../images/Marcas/Creacion_marca_emilan.png') center/cover no-repeat;

            // transform: scale(.8);
            @include mobile {
              background: url('../../images/Marcas/Creacion_marca_emilan.png') center /cover no-repeat;

            }

          }
        }
      }

      &.sirimiri {

        .head_card {
          margin: 0 0 0 0;
          background-color: $siri_purple;

          @include mobile {
            padding: 15px 0 0 0;
          }
        }

        .img_card {
          width: 85px;
          margin-bottom: 20px;

          @include mobile {
            padding-bottom: 15px;
            margin-bottom: 20px;
          }

        }

        .text_header {
          padding: 0 17px;
        }

        .body_card {
          .image {
            background: url('../../images/Marcas/Creacion_marca_sirimiri.png') center /cover no-repeat;

            @include mobile {
              background: url('../../images/Marcas/Creacion_marca_sirimiri.png') center /cover no-repeat;

            }
          }
        }
      }

      &.nubi {
        .head_card {
          margin: 0 0 0 0;

          @include mobile {
            padding: 17px 0 0 0;
          }
        }

        .img_card {
          @include mobile {
            padding-bottom: 20px;
            margin-bottom: 20px;
            width: 160px;
          }
        }

        .text_header {
          @include mobile {
            padding: 0 20px;
          }
        }

        .body_card {
          .image {
            background: url('../../images/Marcas/Creacion_marca_nubi.png') center/cover no-repeat;

            // transform: scale(0.9);
            @include mobile {
              background: url('../../images/Marcas/Creacion_marca_nubi.png') center /cover no-repeat;

            }

          }
        }
      }

      &.priceswitch {
        .head_card {
          background-color: $price_orange;
          margin: 0 0 0 0;

          @include mobile {
            padding: 25px 0 0 0;
          }
        }

        .img_card {
          width: 130px;

          @include mobile {
            padding-bottom: 20px;
            margin-bottom: 20px;
          }
        }

        .text_header {
          line-height: 1.3;

          @include mobile {
            padding: 0 20px;
          }
        }

        .body_card {
          .image {
            background: url('../../images/Marcas/Creacion_marca_price.png') center/cover no-repeat;

            @include mobile {
              background: url('../../images/Marcas/Creacion_marca_price.png') center /cover no-repeat;
            }
          }
        }
      }
    }

  }

  .div9 {
    width: 100%;
    height: 750px;
    min-height: auto;
    padding: 50px 0 0 0;

    @include mobile {
      padding: 40px 0 40px 0;
      // margin-bottom: 40px;
    }

    .titulo {
      margin: 0 auto;
      width: 180px;

      @include mobile {
        width: 82px;
      }
    }

    .carrusell {
      .swiper-slide {
        // padding: 0 63px;
        padding: 0 4%;
      }

      @include mobile {
        padding: 15px 0;

        .swiper {
          padding: 0 30px;

          .swiper-slide {
            padding: 0 0px;
          }
        }
      }
    }

    .card_blog {
      display: block !important;
      position: relative;
      max-width: 300px;
      min-width: 250px;
      height: auto;
      background-color: #FFF;
      box-shadow: 10px 10px 20px #00000029;
      border-radius: 20px;
      // margin-left: 10%;
      margin: 0 auto;

      @include mobile {
        margin: auto;
        height: 457px;

      }

      @media (max-width: 480px) {
        margin-left: 10%;
      }

      .head_card {
        position: relative;
        width: 1005;
        height: 184px;

        .subtitle_blog {
          text-align: center;
          position: absolute;
          top: 57px;
          right: 7px;
          font-family: 'raleway-bold';
          font-size: 20px;
          color: $blue-font;
          width: 160px;
        }
      }

      .body_card {
        padding: 12px 17px 40px;

        .pr_card {
          margin: 0;
          font-family: 'raleway-regular';
          font-size: 15px;
          color: $gray1;

          span {
            font-family: 'raleway-bold';
          }

          &.texto {
            margin: 10px 0 20px 0;
          }
        }

        .boton_white {
          width: 107px;
          display: block;
          margin: 0 auto;

          @include mobile {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

    }

    .boton {
      display: block;
      margin: 0 auto;
      width: 134px;
      font-size: 20px;
      padding: 0 12px;

      @include mobile {
        margin: 20px auto 10px;
        height: 35px;
        font-size: 14px;
        padding: 10px 12px;
      }
    }
  }

  .div10 {
    width: 100%;
    height: 500px;
    padding: 50px 10% 0;
    background: url('../../images/background_contacto.png') center /cover no-repeat;

    @include mobile {
      height: 736px;
      padding: 60px 5% 0;
      background: url('../../images/back_contact_mobile.png') center /cover no-repeat;
    }

    .contact {
      display: flex;

      @include mobile {
        flex-direction: column;
      }

      .message_contact {
        width: 51%;

        @include mobile {
          width: 100%;
        }

        .message {
          text-align: center;
          width: 380px;
          margin-left: auto;
          margin-right: 0px;

          @include mobile {
            width: 100%;
          }

          .tit_message {
            font-family: 'raleway-black';
            color: $azul-medio;

            @include mobile {
              font-size: 25px;
            }
          }

          .prf_message {
            margin: 15px 0;
            padding: 0 10px;
            line-height: 1.33;

            @include mobile {
              font-size: 17px;
              margin: 10px 0;
              padding: 0 8%;
              line-height: 1.2;
            }
          }
        }

      }

      .form_contact {
        width: 48%;
        padding: 10px 10px 10px 53px;
        display: flex;
        flex-direction: column;
        gap: 34px;

        @include mobile {
          width: 100%;
          padding: 3px 15px;
          gap: 10px;
        }

        .icon {
          width: 18px;
          height: 17px;
          background-color: $blue-font;
        }

        .row {
          display: flex;
          width: 100%;
          gap: 15px;

          .ext {
            width: 90px;
          }

          .teleph {
            width: 80%;
          }
        }

        .label_contact {
          position: relative;
          display: flex;
          height: 27px;
          border-bottom: 1px solid $purpura;

          .input_contact {
            background-color: transparent;
            border: none;
            border-radius: 15px 15px 0 0;
            padding: 0 20px 0 25px;
            flex: 1;
            color: $blue-font;
            font-size: 17px;

            &:focus {
              outline: none;
              background-color: #19d3e070;
            }

            &::placeholder {
              font-family: 'raleway-medium';
              font-size: 17px;
              color: $blanco;

              @include mobile {
                font-size: 15px;
              }
            }

            &:valid {
              background-color: transparent;

              @include mobile {}
            }
          }

          .select.input_contact {
            color: #FFFFFF;

            &::-webkit-scrollbar {
              display: none;
            }

            .option {
              background-color: #19d3e070;

            }
          }

          .icon {
            position: absolute;
            top: 5px;
            left: 5px;
          }

          .icon-caretUp,
          .icon-caretDown {
            width: 10px;
            height: 10px;
            background-color: $purpura;
            top: 70%;
            left: 85%;
          }

          .icon-caretUp {
            top: 20%;
          }
        }

        textarea {
          width: 100%;
          height: 120px;
          border-radius: 15px;
          border: 1px solid $purpura;
          padding: 10px 10px 0 10px;
          resize: none;
          background-color: transparent;
          color: $blue-font;
          font-size: 17px;

          @include mobile {
            height: 75px;
          }

          &:focus {
            outline: none;
            background-color: #19d3e080;
          }

          &::placeholder {
            font-family: 'raleway-medium';
            font-size: 17px;
            color: $blanco;

            @include mobile {
              font-size: 15px;
            }
          }
        }

        .boton {
          width: 210px;
          display: block;
          margin: -10px auto 0;
          font-size: 20px;

          @include mobile {
            margin: 10px auto;
            width: 170px;
            height: 33px;
            font-size: 16px;
          }
        }

        .sendOk {
          margin-top: 0px;
          background-color: #00FF0040;
          border-radius: 15PX;
        }

      }
    }
  }

}

.invalide {
  border: 0.5px solid #ff000070;
  box-shadow: 0 0 10px 3px #ff000070;
}