@import '../components/Buton_x.scss';

.big_logo {
  position: absolute;
  top: -61px;
  left: -42px;
  width: 290px;
  height: 300px;
  background: url('../../images/bubble_back_logo.svg') center center / contain no-repeat;
  z-index: 2;
  opacity: 1;
  transition: all .7s ease-out;

  &.bye {
    height: 60px;
    opacity: 0;
  }

  img {
    width: 65%;
    // height: 80%;
    display: block;
    margin: 79px 0 0 67px;

    &.bye {
      transition: all .5s ease-out;
      height: 60px;
      width: 60px;
      opacity: 0;
      margin: 70px 0 0 118px;
    }
  }
}

.big_logo_mobile {
  position: absolute;
  top: -3px;
  left: 50%;
  width: 100%;
  height: 227px;
  background: url('../../images/bubble_back_logo.svg') bottom center / 110% no-repeat;
  z-index: 2;
  opacity: 1;
  transform: translateX(-50%);
  transition: all .7s ease-out;
  // display: none;

  @include desktop-min {
    display: none;
  }

  &.bye {
    height: 60px;
    opacity: 0;
  }

  img {
    width: 98px;
    height: 165px;
    display: block;
    margin: 20px auto 0;

    &.bye {
      transition: all .5s ease-out;
      height: 0px;
      opacity: 0;
      // margin: 70px 0 0 118px;
    }
  }
}

.header_mobile {

  position: sticky !important;
  top: 0px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $blue-font;
  color: #FFF;
  padding: 0 10px 0 15px;
  z-index: 2;

  @include desktop-min {
    display: none;
  }

  .boton_menu {
    transition: all .3s ease-in;
    z-index: 5;

    .div_menu_icon {
      background-color: #FFF;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      cursor: pointer;

      .menu-icon-wrapper {
        z-index: 4;
      }
    }


  }

  .nav_bar {
    background-color: #E8ECF7;
    width: 230px;
    height: 370px;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 5% 0 8.5%;
    border-radius: 0 0 20px 0;
    box-shadow: 6px 6px 10px #00000063;
    // display: none;
    transition: all .4s ease-in-out;
    transform: translateX(-100%);
    opacity: 0;
    z-index: 3;



    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 0;
      font-family: 'raleway-bold';
      font-size: 16px;
      margin: 0;

      a,
      li {
        text-decoration: none;
        width: 100%;
        display: flex;
        // text-align: center;
        text-transform: capitalize;
        align-items: center;
        color: #AAAAAA;
        border-bottom: 1px solid $gray1;
        cursor: pointer;

        &:hover {
          .icon {
            background-color: $blue-font;
          }

          .nav_link {
            color: $blue-font;
          }
        }

        .icon {
          min-width: 30px;
          margin-right: 10px;
          background-color: #AAAAAA;
          height: 30px;
        }

        .icon-blog,
        .icon-contac {
          min-width: 25px;
          margin-right: 15px;
          height: 25px;
        }

        .nav_link {
          display: flex;
          align-items: center;
          flex: 1;
          // justify-content: center;
          // min-height: 30px;
          // min-width: 80px;
          width: 100%;
          height: 50px;
        }
      }

      a {
        border-bottom: none;
      }
    }

  }

  input {
    display: none;
  }

  #menu:checked~.nav_bar {
    // display: flex;
    opacity: 1;
    transform: translateX(0%);
  }

  .nav_bar.active {
    // display: flex;
    opacity: 1;
    transform: translateX(0%);
  }

  .logo {
    // width: 20%;
    margin: 0 30px;

    .icon {
      background-color: #FFF;
      width: 100px;
      height: 40px;
      // margin: 0 0 0 50px;
    }
  }

  .button_header {
    display: block;
    position: relative;
    width: max-content;
    min-width: 88px;
    height: 23px;
    padding: 3px 10px 5px;
    border: 1px solid $blanco;
    border-radius: 21px;
    background-color: $blue-font;
    font-family: 'raleway-medium';
    font-size: 12px;
    color: #FFF;
    text-decoration: none;
    z-index: 5;

    &:hover {
      text-decoration: none !important;
      background-color: $azul-medio;
      cursor: pointer;
    }
  }
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  background-color: $blue-font;
  color: #FFF;
  padding: 0;
  z-index: 2;

  @include mobile {
    display: none;
  }

  .logo {
    width: 30%;

    .icon {
      background-color: #FFF;
      width: 60px;
      height: 60px;
      margin: 0 0 0 50px;
    }
  }

  .nav_bar {
    display: flex;
    align-items: center;
    width: 80%;
    // padding: 0 5% 0 5.5%;

    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4.5% 0 5.2%;
      font-family: 'raleway-bold';
      font-size: 12px;

      a {
        text-decoration: none;
        color: $blanco;
      }

      li {
        text-align: center;
        text-transform: uppercase;

        &.active {
          border-bottom: 1px solid $blanco;
        }

        &:hover {
          border-bottom: 1px solid $blanco;
          cursor: pointer;
        }

        .nav_link {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-height: 20px;
          min-width: 80px;
          width: 100%;
          height: 100%;



        }
      }
    }

  }

  .button_header {
    width: max-content;
    min-width: 112px;
    height: 29px;
    padding: 5px 10px;
    margin-right: 15px;
    border: 1px solid $blanco;
    border-radius: 21px;
    background-color: $blue-font;
    font-family: 'raleway-medium';
    font-size: 14px;
    text-decoration: none;
    color: #FFF;

    &:hover {
      text-decoration: none !important;
      background-color: $azul-medio;
      cursor: pointer;
    }
  }
}

.langs {
  position: relative;
  max-width: auto;
  width: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  z-index: 3;

  // @include mobile {
  //   width: 120px;
  //   position: absolute;
  //   right: 10px;
  //   top: 50px;
  //   z-index: 3;

  // }

  .botones {
    position: absolute;
    top: 135%;
    left: 5px;
    background-color: #00000063;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    display: none;


    @include mobile {
      top: 114%;
      left: 10px;
    }


    .bt {
      color: #FFF;
      border: 0;
      background: transparent;
      font-size: 16px;
      height: auto;
      width: auto;
      min-width: 60px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: flex-start;

      @include mobile {}

      &:first-child {
        margin-right: 5px;
        margin: 0;

        @include mobile {}
      }

      &.active {
        color: #00e5ba;
      }

      &:hover {
        color: #00e5ba;
      }

      .flag {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  .idioma {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #FFF;
    gap: 5px;
    padding: 10px 10px 10px 15px;
    border-left: 1px solid #FFF;

    @include mobile {
      padding: 10px;
      margin-left: 10px;
      font-size: 14px;
    }

    .flag {
      min-height: 20px;
      min-width: 20px;
    }

    .icon {
      margin-top: 7px;
      width: 12px;
      height: 12px;
      background-color: #FFF;

      @include mobile {
        margin-top: 3px;
      }
    }
  }
}