.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  font-family: 'raleway-regular';

  .modal-wrapper {
    position: relative;
    width: 80%;
    height: 80%;
  }

  .modal {
    background: white;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
      /* Tamaño del scroll en vertical */
      height: 8px;
      /* Tamaño del scroll en horizontal */
      display: none;
      /* Ocultar scroll */
    }

    /* Ponemos un color de fondo y redondeamos las esquinas del track */
    &::-webkit-scrollbar-track {
      background: #e1e1e1;
      border-radius: 4px;
    }

    /* Cambiamos el fondo cuando esté en active o hover */
    &::-webkit-scrollbar-track:hover,
    &::-webkit-scrollbar-track:active {
      background: #d4d4d4;
    }

    /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 4px;
    }

    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }

    /* Cambiamos el fondo cuando esté en active */
    &::-webkit-scrollbar-thumb:active {
      background-color: #999999;
    }
  }

  .modal-header {
    position: absolute;
    top: 5px;
    right: 15px;
    display: flex;
    justify-content: flex-end;
    font-size: 35px;
    line-height: 1;
  }

  .title_modal {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
  }

  // .modal-body {}
  &.modal-marcas {
    // position: absolute;
    display: block;
    background-color: rgba(0, 0, 0, 0.05);
    height: auto;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    @include mobile {
      height: 360%;
    }

    .modal-wrapper {
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1200px;
      width: 90%;
      height: max-content;
      padding: 0 0 50px 0;

      @include mobile {
        top: 2.2%;
      }
    }

    .modal {
      background: url('../../images/paper-texture.png') center center / contain repeat;
      // background: white;
      height: 100%;
      width: 100%;
      border-radius: 15px;
      overflow-y: auto;
      color: aqua;

      .ex {
        font-family: 'Luckiest Guy', cursive;
        font-size: 30px;
        color: $blue-font;
        text-decoration: none;
      }

      h2 {
        margin: 3px 0 15px;
        text-align: center;
        font-family: 'Luckiest Guy', cursive;
        font-size: 30px;
        color: $blue-font;
        padding: 0 20px;
      }

      .description {
        font-family: 'raleway-regular';
        font-size: 25px;
        margin: 0 0 30px;
        color: $blue-font;
        text-align: center;
      }

      img {
        display: block;
        margin: auto;
        width: 1140px;
        height: 700px;
        margin-bottom: 30px;

        @include mobile {
          width: 100%;
          max-width: 345px;
          height: auto;
        }
      }
    }
  }

}