html {
  font-family: 'raleway-medium';
  box-sizing: border-box;
  font-size: 62.5%;
  /* 62.5% = 10px */
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: url('../../images/paper-texture.png') center center / contain repeat;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 4.5rem;
}

h3 {
  font-size: 4rem;
}

h4 {
  font-size: 3.5rem;
}

h5 {
  font-size: 3rem;
}

small,
.small {
  font-size: 1rem;
}

img {
  width: 100%;
  height: auto;
}

select {
  appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.App {
  min-height: 100vh;
}