.div_article {
  padding: 46px 12%;

  @include mobile {
    padding: 0 9% 150px;
  }

  .article {
    width: 100%;
    // max-width: 980px;
    background-color: $blanco;
    box-shadow: 10px 10px 20px #00000029;

    @include mobile {
      background-color: transparent;
      box-shadow: none;
    }

    .head_article {
      position: relative;
      height: 399px;
      background-color: $azul-claro;
      overflow: hidden;

      @include mobile {
        height: 183px;
      }

      figure {
        height: 100%;
      }

      img,
      .figure-bkp {
        // width: 80%;
        // object-fit: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 100%;

        // margin: 0 0 0 10%;
        @include mobile {
          width: 100%;
          height: 100%;
          object-position: left;
        }
      }

      .titulo_articulo {
        width: 430px;
        width: 40%;
        position: absolute;
        top: 50px;
        right: 58px;
        font-family: 'raleway-bold';
        font-size: 40px;
        color: $blue-font;
        text-align: center;

        @include mobile {
          width: 100%;
          top: 23px;
          right: 4px;
          height: 100%;
          font-size: 20px;
        }
      }
    }

    .body_article {
      position: relative;
      padding: 20px 9.5% 100px;

      @include mobile {
        padding: 0px 0 0;
      }

      .titulo_articulo {
        margin: 0 0 2rem 0;
        font-family: 'raleway-bold';
        font-size: 40px;
        color: $blue-font;
        text-align: center;

        @include mobile {
          margin: 1rem 0;
          width: 100%;
          top: 23px;
          right: 4px;
          height: 100%;
          font-size: 20px;
        }
      }

      .flex {
        // align-items: center;

        .info_art {
          margin-left: 40px;
          width: 50%;

          @include mobile {
            margin-left: 0;
          }

          p {
            font-family: 'raleway-medium';
            color: $gray1;
            font-size: 15px;

            span {
              font-family: 'raleway-bold';
            }
          }
        }

        .social_art {
          display: flex;
          width: 50%;
          height: 40px;

          @include mobile {
            position: absolute;
            width: 100%;
            bottom: -50px;
            left: -15px;
          }

          .bt_blue {
            display: flex;
            align-items: center;

            .div_icon {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 35px;
              height: 35px;
              border-radius: 10px;
              border: 1px solid $azul-medio;
              box-shadow: inset 0px 3px 6px #00000029;
              margin: 0 15px;
              cursor: pointer;

              &:hover,
              &:focus,
              &.lk {
                background-color: $azul-medio;

                .icon {
                  background-color: $blanco;
                }
              }

              .icon {
                width: 17.5px;
                height: 19px;
                background-color: $azul-medio;

                &.icon-heart {
                  height: 15px;
                }

              }

            }

            span {
              font-family: 'raleway-medium';
              color: $azul-medio;
              font-size: 16px;
            }
          }

          .likes {}

          .social_share {
            position: relative;
            display: flex;

            .share {}

            .btns_share {
              background-color: $back_compartir;
              width: 226px;
              height: 43px;
              position: absolute;
              top: 110%;
              left: 30px;
              // display: flex;
              align-items: center;
              padding: 10px;
              justify-content: space-between;
              box-shadow: 0px 3px 6px #00000029;
              border-radius: 0px 10px 10px 10px;
              display: none;

              &.show {
                display: flex;
              }

              @include mobile {}

              .react-share__ShareButton {
                display: flex;
                justify-content: center;
                width: 20%;
              }

              .icon {
                width: 20px;
                height: 20px;
                background-color: $blanco;
                cursor: pointer;

                &:hover,
                &:focus {
                  background-color: $purpura;
                }
              }
            }
          }
        }
      }

      .text_art {
        p {
          font-family: 'raleway-regular';
          font-size: 15px;
          color: $gray1;
          margin: 2rem 0;
        }

        ul,
        ol {
          li {
            font-family: 'raleway-regular';
            font-size: 15px;
            color: $gray1;
          }

          li::marker {
            color: $azul-medio;
          }
        }

        ul {
          li::before {
            content: "\2022";
            color: $purpura;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }

        img {
          display: block;
          max-width: 400px;
          margin: 0 auto;
        }

        a {
          color: $azul-claro;
        }

        a:visited {
          color: $purpura;
        }
      }
    }
  }
}