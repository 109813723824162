//colors

$azul-claro: #19D3E0;
$azul-medio: #475DF1;
$purpura: #7519FF;
$blue-font: #0033A0;
$blue-font2: #0B1EBD;

$gray1: #707070;
$gray_light: #BFBFBF;
$gray_back_video: #E9E9E9;
$gray_back_footer: #F0F0F0;
$back_compartir: #E0E3EB;

$blue_back_footer: #19D3E029;
$blue_back_equipo: #19D3E01A;
$blue_back_card_small2: #475DF11A;
$blue_back_card_small1: #0033A012;
$blue_back_card_big: #475DF10F;
$blue_back_aun: #0033A017;
$back_compartir: #E1E5FD;


$purp_back_10: #7519FF1F;
$siri_purple: #3D0080;
$emilan_blue: #000540;
$price_orange: #FF5617;

$blanco: #FFFFFF;
$negro: #000000;

$fontRr: 'raleway-regular';
$fontRm: 'raleway-medium';
$fontRb: 'raleway-bold';
$fontRsb: 'raleway-semi-bold';
$fontRbk: 'raleway-black';

$fontQr: 'quicksand-regular';
$fontQb: 'quicksand-bold';

$fontP: 'product-sans';
$fontPb: 'product-sans-bold';

$fontLG: 'Luckiest Guy';

$fontRU: 'Rubik';

$bakgroundGradient: transparent linear-gradient(180deg, #19D3E0 0%, #475DF1 47%, #7519FF 100%) 0% 0% no-repeat padding-box;
$shadow1: 3px 3px 6px #00000029;
$shadow2: 3px 3px 10px #00000029;
$shadow3: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
$shadow4: inset 6px 6px 6px #0000001F, 3px 5px 5px #00000029;