.headerLanding {
  .big_logo {
    position: absolute;
    top: -65px;
    left: 2px;
    width: 300px;

    img {
      width: 64%;
      display: block;
      margin: 82px 0 0 22px;
    }
  }

  .button_header {
    text-align: center;
    margin-right: 12px;
  }

  .langs {
    .idioma {
      padding: 10px;
      font-size: 15px;

      .flag {
        width: 27px;
        height: 27px;
      }
    }

    .botones {
      width: 93%;
      top: 122%;
      left: 8px;
      gap: 0;
      padding: 0 0 0 11px;
      align-items: flex-start;

      .bt {
        font-family: $fontRsb;
        font-size: 13px;
        height: 37px;
        gap: 6px;

        &:first-of-type {
          border-bottom: 1px solid $blanco;
        }

        .flag {
          margin: 0;
        }
      }
    }
  }
}

.headerLanding_mb {
  @include mobile {
    .langs {
      .botones {
        width: 90%;
        top: 112%;
        left: 22px;
      }

      .bt {
        font-size: 14px;

      }
    }
  }
}