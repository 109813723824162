// USO className=".icon .icon_name"

.icon {
  content: " ";
  background: #000;
  height: 25px;
  width: 25px;
  position: relative;
  z-index: 1;
  display: flex;
  background-size: contain;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

// como mascara 

// .icon_name {
//   -webkit-mask-image: url("./../../images/name.svg");
//   mask-image: url("./../../images/name.svg");
// }

//varios
.icon-add {
  -webkit-mask-image: url("./../../images/add.svg");
  mask-image: url("./../../images/add.svg");
}

.icon-user {
  -webkit-mask-image: url("./../../images/User.svg");
  mask-image: url("./../../images/User.svg");
}

.icon-phone {
  -webkit-mask-image: url("./../../images/Phone.svg");
  mask-image: url("./../../images/Phone.svg");
}

.icon-email {
  -webkit-mask-image: url("./../../images/Envelope.svg");
  mask-image: url("./../../images/Envelope.svg");
}

.icon-location {
  -webkit-mask-image: url("./../../images/Location.svg");
  mask-image: url("./../../images/Location.svg");
}

.icon-location_map {
  -webkit-mask-image: url("./../../images/Map-location.svg");
  mask-image: url("./../../images/Map-location.svg");
}

.icon-playRound {
  -webkit-mask-image: url("./../../images/play_round.svg");
  mask-image: url("./../../images/play_round.svg");
}

.icon-send {
  -webkit-mask-image: url("./../../images/Send.svg");
  mask-image: url("./../../images/Send.svg");
}

//
.icon-creacion {
  -webkit-mask-image: url("./../../images/creacion.svg");
  mask-image: url("./../../images/creacion.svg");
}

.icon-desarrollo {
  -webkit-mask-image: url("./../../images/desarrollo.svg");
  mask-image: url("./../../images/desarrollo.svg");
}

.icon-estrategia {
  -webkit-mask-image: url("./../../images/estrategia.svg");
  mask-image: url("./../../images/estrategia.svg");
}

.icon-pageLand {
  -webkit-mask-image: url("./../../images/page_land.svg");
  mask-image: url("./../../images/page_land.svg");
}

.icon-pauta {
  -webkit-mask-image: url("./../../images/pauta.svg");
  mask-image: url("./../../images/pauta.svg");
}

.icon-redes {
  -webkit-mask-image: url("./../../images/redes.svg");
  mask-image: url("./../../images/redes.svg");
}

.icon-redes2 {
  -webkit-mask-image: url("./../../images/search_redes.svg");
  mask-image: url("./../../images/search_redes.svg");
}

.icon-logoK {
  -webkit-mask-image: url("./../../images/logo_k.svg");
  mask-image: url("./../../images/logo_k.svg");
}

.icon-logoK_mobile {
  -webkit-mask-image: url("./../../images/logo_mobile.svg");
  mask-image: url("./../../images/logo_mobile.svg");
}

.icon-logoK_vertical {
  -webkit-mask-image: url("./../../images/logo_vertical.svg");
  mask-image: url("./../../images/logo_vertical.svg");
}

//carets
.icon-caretUp {
  -webkit-mask-image: url("./../../images/CaretUp.svg");
  mask-image: url("./../../images/CaretUp.svg");
}

.icon-caretDown {
  -webkit-mask-image: url("./../../images/CaretDown.svg");
  mask-image: url("./../../images/CaretDown.svg");
}

.icon-caretLeft {
  -webkit-mask-image: url("./../../images/CaretLeft.svg");
  mask-image: url("./../../images/CaretLeft.svg");
}

.icon-caretRight {
  -webkit-mask-image: url("./../../images/CaretRight.svg");
  mask-image: url("./../../images/CaretRight.svg");
}

.icon-btn_left {
  -webkit-mask-image: url("./../../images/btn_left.svg");
  mask-image: url("./../../images/btn_left.svg");
}

.icon-btn_right {
  -webkit-mask-image: url("./../../images/btn_right.svg");
  mask-image: url("./../../images/btn_right.svg");
}

// redes

.icon-smallFace {
  -webkit-mask-image: url("./../../images/small_face.svg");
  mask-image: url("./../../images/small_face.svg");
}

.icon-smallGoogle {
  -webkit-mask-image: url("./../../images/small_google.svg");
  mask-image: url("./../../images/small_google.svg");
}

.icon-smallTiktok {
  -webkit-mask-image: url("./../../images/small_tiktok.svg");
  mask-image: url("./../../images/small_tiktok.svg");
}

.icon-smallInsta {
  -webkit-mask-image: url("./../../images/small_insta.svg");
  mask-image: url("./../../images/small_insta.svg");
}

.icon-whatsapp {
  -webkit-mask-image: url("./../../images/whatsapp.svg");
  mask-image: url("./../../images/whatsapp.svg");
}

.icon-heart {
  -webkit-mask-image: url("./../../images/Heart.svg");
  mask-image: url("./../../images/Heart.svg");
}

.icon-share {
  -webkit-mask-image: url("./../../images/Share.svg");
  mask-image: url("./../../images/Share.svg");
}

.icon-facebook {
  -webkit-mask-image: url("./../../images/Facebook.svg");
  mask-image: url("./../../images/Facebook.svg");
}

.icon-instagram {
  -webkit-mask-image: url("./../../images/Instagram_purple.svg");
  mask-image: url("./../../images/Instagram_purple.svg");
}

.icon-twiter {
  -webkit-mask-image: url("./../../images/Twitter.svg");
  mask-image: url("./../../images/Twitter.svg");
}

.icon-whatsapp_white {
  -webkit-mask-image: url("./../../images/Whatsapp_white.svg");
  mask-image: url("./../../images/Whatsapp_white.svg");
}

.icon-messenger {
  -webkit-mask-image: url("./../../images/Facebook-messenger.svg");
  mask-image: url("./../../images/Facebook-messenger.svg");
}

.icon-linkedin {
  -webkit-mask-image: url("./../../images/Linkedin.svg");
  mask-image: url("./../../images/Linkedin.svg");
}

.icon-reply {
  -webkit-mask-image: url("./../../images/Reply.svg");
  mask-image: url("./../../images/Reply.svg");
}

.icon-wApp_color {
  -webkit-mask-image: url("./../../images/whtpp_colors.svg");
  mask-image: url("./../../images/whtpp_colors.svg");
}

.icon-mess_color {
  -webkit-mask-image: url("./../../images/mess_colors.svg");
  mask-image: url("./../../images/mess_colors.svg");
}

.icon-wApp_blue {
  -webkit-mask-image: url("./../../images/whtpp_blue.svg");
  mask-image: url("./../../images/whtpp_blue.svg");
}

.icon-mess_blue {
  -webkit-mask-image: url("./../../images/messenger_blue.svg");
  mask-image: url("./../../images/messenger_blue.svg");
}

.icon-home {
  -webkit-mask-image: url("./../../images/Home.svg");
  mask-image: url("./../../images/Home.svg");
}

.icon-servic {
  -webkit-mask-image: url("./../../images/Shopping-bag.svg");
  mask-image: url("./../../images/Shopping-bag.svg");
}

.icon-price {
  -webkit-mask-image: url("./../../images/Award.svg");
  mask-image: url("./../../images/Award.svg");
}

.icon-your {
  -webkit-mask-image: url("./../../images/logo_kreative_menu.svg");
  mask-image: url("./../../images/logo_kreative_menu.svg");
}

.icon-bulb {
  -webkit-mask-image: url("./../../images/Lightbulb-alt.svg");
  mask-image: url("./../../images/Lightbulb-alt.svg");
}

.icon-blog {
  -webkit-mask-image: url("./../../images/Book-open.svg");
  mask-image: url("./../../images/Book-open.svg");
}

.icon-contac {
  -webkit-mask-image: url("./../../images/Chat.svg");
  mask-image: url("./../../images/Chat.svg");
}

.icon-ex {
  -webkit-mask-image: url("./../../images/Cross.svg");
  mask-image: url("./../../images/Cross.svg");
}




// como background 

// .icon-next {
//   background-color: transparent;
//   background-image: url("./../../Images/archivo.svg");
// }
.icon-menu {
  background-color: transparent;
  background-image: url("./../../images/icon_menu.svg");
}

.icon-medalla {
  background-color: transparent;
  background-image: url("./../../images/medalla.png");
}

.icon-medallaBG {
  background-color: transparent;
  background-image: url("./../../images/medalla_black-gold.png");
}

.icon-medallaG {
  background-color: transparent;
  background-image: url("./../../images/medalla_oro.png");
}

.icon-medallaS {
  background-color: transparent;
  background-image: url("./../../images/medalla_plata.png");
}

.icon-faceBUB {
  background-color: transparent;
  background-image: url("./../../images/face_bub.svg");
}

.icon-instaBUB {
  background-color: transparent;
  background-image: url("./../../images/insta_bub.svg");
}

.icon-tiktokBUB {
  background-color: transparent;
  background-image: url("./../../images/tiktok_bub.svg");
}

.icon-youtuBUB {
  background-color: transparent;
  background-image: url("./../../images/youtu_bub.svg");
}

//
// flags

.flag-es {
  background-image: url('./../../images/spanish.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.flag-en {
  background-image: url('./../../images/english.png');
  background-repeat: no-repeat;
  background-size: cover;
}