  .div5 {

    width: 100%;
    min-height: 993px;
    padding: 45px 0 0 0;
    background-color: $blue_back_aun;

    @include mobile {
      padding: 25px 0;
    }

    &.divPrices {
      @include mobile {
        display: none;
      }
    }

    &.carrPrices {
      min-height: auto;

      .carrusell {
        margin-top: -20px;
      }

      .carrusell .swiper {
        width: 100%;
        padding: 0 45px;
      }

      .carrusell .swiper .swiper-wrapper {
        box-sizing: border-box;
        padding: 19px 0 21px;
      }

      .swiper-slide {
        padding: 0 !important;
      }


      .dn {
        display: none;

        &.ok {
          display: block;
        }
      }

      @include desktop-min {
        display: none;
      }

      .plan {
        @include mobile {
          margin: 0px auto;
        }
      }
    }

    .titulo {
      margin: 0px 14% 16px;

      @include mobile {
        margin: 0px 21% 10px;
      }
    }

    .subtitle {
      margin: 0;
      font-size: 30px;
      text-align: center;
      margin-bottom: 44px;

      @include mobile {
        font-size: 20px;
        margin-bottom: 0;
      }
    }

    .planes {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;

      .plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 280px;
        height: 512px;
        background: url('../../images/price1.svg') 0% 0% / cover no-repeat;
        color: $blue-font;

        .medalla {
          display: flex;
          justify-content: center;
          margin-bottom: 18px;


          .icon {
            width: 120px;
            height: 120px;
          }
        }

        .name_plan {
          font-family: 'raleway-black';
          font-size: 20px;
          margin-bottom: 15px;
        }

        .price_plan {
          display: flex;
          align-items: flex-start;
          font-family: 'raleway-semi-bold';
          font-size: 20px;
          margin-bottom: 59px;
          margin-left: 10px;


          span {
            font-family: 'raleway-bold';
            font-size: 51px;
            line-height: 30px;
          }
        }

        .serv_plan {
          padding: 0 12% 0 14%;
          margin-bottom: 40px;


          .flex {

            align-items: center;
            gap: 5px;

            .icon_s {
              display: grid;
              grid-template-columns: auto 9px;
              grid-template-rows: 1fr;

              .icon {
                width: 28px;
                height: 28px;
                background-color: $azul-claro;
              }

              .icon-smallFace,
              .icon-smallGoogle,
              .icon-smallTiktok,
              .icon-smallInsta {
                width: 8.5px;
                height: 8.5px;
              }

            }

            .p_serv {
              // margin-left: 10px;
              font-family: 'raleway-regular';
              font-size: 15px;
              color: $blanco;
            }

          }
        }

        .boton_blue {}
      }

      .plan_platino {
        .name_plan {}

        .price_plan {
          margin-bottom: 50px;
        }

        .serv_plan {
          margin-bottom: 30px;

          .flex {
            gap: 10px;
          }
        }
      }

      .plan_oro {
        .name_plan {}

        .price_plan {
          margin-bottom: 40px;
        }

        .serv_plan {
          padding: 2px 11% 0 16%;
          margin-bottom: 8px;


          .flex {
            align-items: center;
            margin-bottom: 9px;
            gap: 12px;

            .p_serv {
              margin: 0;
            }

          }
        }
      }

      .plan_medida {
        background: url('../../images/price2.svg') 0% 0% / cover no-repeat;

        .name_plan {
          margin-bottom: 46px;
        }

        .serv_plan {
          padding: 25% 20%;
          margin-bottom: 13px;

          .flex {
            .p_serv {
              text-align: center;
            }

          }
        }
      }
    }

    .terminos_planes {
      padding: 0 10.2%;

      .sub {
        margin: 41px 0 0;
        color: $blue-font;
        font-size: 20px;

        @include mobile {
          color: $azul-medio;
          font-size: 18px;
          text-align: center;
          margin: 0 0 0 0;
          cursor: pointer;

          &:hover {
            color: $purpura;
          }
        }
      }

      .prf_term {
        margin: 5px 0;
        color: $gray1;
        line-height: 1.3;
      }
    }
  }