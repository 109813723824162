  .footer {
    width: 100%;
    height: 193px;
    padding: 15px 10%;
    background-color: $blue_back_footer;
    display: flex;
    justify-content: space-between;
    color: $blue-font;

    @include mobile {
      height: auto;
      flex-direction: column;
      padding: 10px 5%;
    }

    .subtitle {
      text-transform: uppercase;
      font-family: 'raleway-bold';
      font-size: 17px;
      margin-bottom: 15px;
      margin-top: 0;
      width: max-content;

      @include mobile {
        width: auto;
        margin-bottom: 12px;
        text-align: center;
      }
    }

    .contact_info {
      width: 300px;
      margin: 15px 0 0 10px;

      @include mobile {
        width: 100%;
        margin: 15px 0 0 0px;
      }

      .flex {
        flex-direction: column;

        @include mobile {
          flex-direction: row;
          justify-content: center;
          column-gap: 40px;
          flex-wrap: wrap;

          .inf2 {
            order: 1;
          }
        }
      }

      .info_Contct {
        padding: 0 0 0 12px;
        display: flex;
        font-size: 17px;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 0;

        @include mobile {
          margin-bottom: 10px;
          padding: 0;
        }

        .icon {
          width: 20px;
          height: 20px;
          background-color: $azul-medio;
          margin-right: 8px;
        }

        .icon-phone {
          width: 17px;
          height: 17px;
          margin-right: 11px;
        }
      }
    }

    .social_info {
      width: 280px;
      margin: 15px 0 0 5px;

      @include mobile {
        margin: 10px 0 0;
        width: 100%;
        display: flex;
      }

      .social1,
      .social2 {
        width: 47%;

        @include mobile {
          width: 49%;
        }
      }

      .social_networks {
        display: flex;
        gap: 10px;
        padding: 0 0 0 10px;
        margin-bottom: 15px;

        .icon {
          background-repeat: no-repeat;
        }
      }

      .label_footer {
        width: 230px;
        position: relative;
        display: flex;
        height: 27px;
        border: 1px solid $azul-medio;
        border-radius: 10px;
        left: 10px;
        top: -10px;

        @include mobile {
          width: 100%;
          top: 0;
          left: 0;
        }

        input {
          background-color: transparent;
          border: none;
          border-radius: 10px;
          padding: 0 20px 0 25px;
          flex: 1;
          color: $blue-font;
          font-size: 17px;

          &:focus {
            outline: none;
            background-color: $azul-claro ;
          }

          &::placeholder {
            font-family: 'raleway-medium';
            font-size: 17px;
            color: $blanco;
          }
        }

        .icon {
          background-color: $azul-medio;
          width: 18px;
          height: 17px;
          position: absolute;
          top: 4px;
          left: 5px;
        }

        .icon-send {
          background-color: $azul-medio;
          width: 13px;
          height: 13px;
          top: 7px;
          left: 92%;
        }
      }
    }

    .terminos_info {
      margin: 15px 15px 0 5px;

      .subtitle {
        cursor: pointer;
      }

      .prf_term_footer {
        @include mobile {
          display: none;
        }

        &.block {
          display: block;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        color: #000;

      }

      li {
        padding: 0 0 0 12px;
        display: flex;
        font-size: 12px;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 0;

        @include mobile {
          justify-content: center;
          margin-bottom: 10px;
          padding: 0;
        }
      }
    }

    .logo_footer {
      margin-right: 20px;

      @include mobile {
        display: none;
      }
    }
  }