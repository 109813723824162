.flex {
  display: flex;
}

.contenedor_home,
.contenedor {
  max-width: 1440px;
  // min-height: calc(100vh - 98px);
  margin: 0 auto;
  // background: url('../../images/paper-texture.png') center center / cover no-repeat;

  h5 {
    margin: 0;
  }

  p {
    font-size: 20px;
    color: $blue-font;
    margin: 10px 0;

    @include mobile {
      font-size: 16px;
    }

    b {
      font-family: 'raleway-bold';
    }
  }

  .subtitle {
    color: $blue-font;
    font-family: 'raleway-bold';
    padding: 0 10px;
    margin-bottom: 10px;

    @include mobile {
      font-size: 20px;
    }

    span {
      font-family: 'raleway-black';
    }

    b {
      font-family: 'raleway-extra-bold';
    }
  }
}

.carrusell {
  padding: 15px 5% 30px;

  .swiper {
    padding: 0 50px;


    .swiper-wrapper {
      box-sizing: border-box;
      padding: 20px 0 30px;

      .swiper-slide {
        padding: 0 30px;

        @include mobile {
          padding: 0 15px;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      left: 3px;
      right: auto;
      width: 57px;
      height: 57px;
      border-radius: 50%;
      background: #FFFFFF;
      box-shadow: 3px 3px 6px #00000029;
      opacity: 1;

      @include mobile {
        width: 30px;
        height: 30px;
      }

      &::after {
        content: url('../../images/CaretLeft.svg') !important;
        // font-size: medium;
        transform: scale(0.6);

      }
    }

    .swiper-button-next {
      left: auto;
      right: 3px;

      &::after {
        content: url('../../images/CaretRight.svg') !important;
      }
    }

    .swiper-button-disabled {
      opacity: 0.35;
    }
  }
}

.big_logo {
  position: absolute;
  top: -61px;
  left: -42px;
  width: 290px;
  height: 300px;
  background: url('../../images/bubble_back_logo.svg') center center / contain no-repeat;
  z-index: 2;
  opacity: 1;
  transition: all .7s ease-out;

  &.bye {
    height: 60px;
    opacity: 0;
  }

  img {
    width: 65%;
    // height: 80%;
    display: block;
    margin: 79px 0 0 67px;

    &.bye {
      transition: all .5s ease-out;
      height: 60px;
      opacity: 0;
      margin: 70px 0 0 118px;
    }
  }
}

.boton {
  width: fit-content;
  min-width: 94px;
  height: 41px;
  padding: 0 15px;
  font-family: 'raleway-medium';
  font-size: 15px;
  color: $blanco;
  border: none;
  border-radius: 20px;
  background: $purpura;
  background: linear-gradient(180deg, rgba(25, 211, 224, 1) 0%, rgba(71, 93, 241, 1) 35%, #7519ff 100%);
  transition: all .3s ease-out;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $azul-claro;
    transform: scale(1.2);
  }
}

.boton_blue {
  width: fit-content;
  min-width: 152px;
  height: 30px;
  padding: 0 10px;
  font-family: 'raleway-bold';
  font-size: 15px;
  color: $blue-font;
  border: none;
  border-radius: 20px;
  background: $azul-claro;
  transition: all .3s ease-out;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $blanco;
    transform: scale(1.05);
  }
}

.boton_white {
  width: fit-content;
  min-width: 100px;
  height: 31px;
  padding: 0 10px;
  font-family: 'raleway-semi-bold';
  font-size: 18px;
  color: $blue-font;
  border: 1px solid $purpura;
  border-radius: 20px;
  background: $blanco;
  transition: all .3s ease-out;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $blanco;
    background-color: $purpura;
    transform: scale(1.05);
  }
}

.boton_gradient {
  width: fit-content;
  min-width: 100px;
  height: 43px;
  padding: 0 15px;
  font-family: $fontRb;
  font-size: 20px;
  color: $blanco;
  border: none;
  border-radius: 25px;
  background: $bakgroundGradient;
  transition: all .3s ease-out;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $blanco;
    background-color: $purpura;
    transform: scale(1.05);
  }
}

.copy {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray_back_footer;

  p {
    margin: 0;
    font-size: 11px;
    color: $gray1;
  }
}

.auto {
  display: block;
  margin: 0 auto;
}

.mobile {
  display: none !important;

  @include mobile {
    display: block !important;
  }
}

.desktop {

  display: block !important;

  @include mobile {
    display: none !important;
  }
}